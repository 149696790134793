body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  margin: auto;
  max-width: 100%;
  overflow-x: hidden;
}

.content {
  /*margin:0 5px;*/
  /*margin-left:80px;*/
  /*background: rgb(233, 231, 231);*/
  /*padding: 10px;*/
  /*height: 100vh;*/
  height:400;
}

.login-content {
  background: rgb(233, 231, 231);
  height: 100vh;
  padding: 50px;

}

.button_sidebar {
  width:100px;
}


/*.ant-table table { font-size: 13px; }*/

.explorer-plot {
  padding:20px;
  margin:20px;
}

.process-list {
  grid-area: explorer;
  min-width: 200px;
  
}

.process-list-item {
  max-height: 40px;
}

.plots {
  background: #fff;
}

.plot1 {
  grid-area: plot1;
  
}
.plot2 {
  grid-area: plot2;
}
.plot3 {
  grid-area: plot3;
}
.plot4 {
  grid-area: plot4;
}
.plot5 {
  grid-area: plot5;
}
.plot6 {
  grid-area: plot6;
}


*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #888
}

*::-webkit-scrollbar-thumb {
  background: #696969
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.my-dropdown-class {
  /* Your styles here */
  overflow-y: scroll !important;
  max-height: 200px; /* Adjust this value according to your needs */
}

/* For Webkit browsers like Chrome, Safari */
.my-dropdown-class::-webkit-scrollbar {
  width: 8px;
}

.my-dropdown-class::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* For Firefox */
.my-dropdown-class {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.custom-modal-overlay {
  position: fixed; /* or absolute */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* Adjust this value as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.rnd-modal {
  z-index: 1000; /* This might be redundant if the parent has a higher z-index */
  /* Other styles for your modal */
}